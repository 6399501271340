import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../../Context/Context";
import FooterService from "../../Service/FooterService";
import { footerSettingUtils } from "../../Service/UtilsService";
import ReactHtmlParser from "react-html-parser";
import CircularProgress from "@material-ui/core/CircularProgress";
import { notification } from "../../Shared/NotificationModal";
import { getFooterSetting, getMenus } from "../../Helper/helper";
import Traveler from "../Account/Travelers/TravelersAddEdit";
import { getCurrency } from "../../Utils/utils";

const Footer = (props) => {
  let footerServ = new FooterService();
  let HomeContext = useContext(Context);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [htmlFooter, setHtmlFooter] = useState(props.htmlFooter || "");
  const [Menu1, setMenu1] = useState(props.footerMenu.menu1 || []);
  const [Menu2, setMenu2] = useState(props.footerMenu.menu2 || []);
  const [Menu3, setMenu3] = useState(props.footerMenu.menu3 || []);
  const [Setting, setSetting] = useState(props.footerSetting || {});
  let pathname = window?.location?.pathname;

  useEffect(() => {
    let pathname = window?.location?.pathname;
    let menu1Data = Menu1.filter((data) => data.link === pathname);
    let menu2Data = Menu2.filter((data) => data.link === pathname);
    let menu3Data = Menu3.filter((data) => data.link === pathname);

    if (menu1Data && menu1Data.length) {
      setHtmlFooter(menu1Data[0].html_footer || "");
      document.title = menu1Data[0].title || "Flights Travel";
    } else if (menu2Data && menu2Data.length) {
      setHtmlFooter(menu2Data[0].html_footer || "");
      document.title = menu2Data[0].title || "Flights Travel";
    } else if (menu3Data && menu3Data.length) {
      setHtmlFooter(menu3Data[0].html_footer || "");
      document.title = menu3Data[0].title || "Flights Travel";
    } else {
      setHtmlFooter("");
      document.title = "Flights Travel";
    }
  }, [
    window.location &&
    window.location.pathname &&
    (Menu1.filter((data) => data.link === window.location.pathname) ||
      Menu2.filter((data) => data.link === window.location.pathname) ||
      Menu3.filter((data) => data.link === window.location.pathname)),
  ]);

  useEffect(() => {
    let pathname = window?.location?.pathname;
    if (
      pathname !== "/flightDetails" &&
      pathname !== "/search" &&
      pathname !== "/googleresults"
    ) {
      document.getElementById("mainFooter").style.paddingTop = 0;
      document.getElementById("mainFooter").style.textAlign = "center";
      document.getElementsByClassName("middle-footer")[0].style.paddingTop =
        "30px";
    } else {
      document.getElementById("mainFooter").style.paddingTop = "30px";
      document.getElementById("mainFooter").style.textAlign = "start";
      document.getElementsByClassName("middle-footer")[0].style.paddingTop =
        "0";
    }
    async function fetchData() {
      window.scroll(0, 0);
      const result = await footerServ.listMenuLink();
      const menu = getMenus(result);
      menu.menu1.push({
        name: "Apply For ESTA",
        link: "https://esta.cbp.dhs.gov/esta",
      });
      setMenu1(menu.menu1);
      setMenu2(menu.menu2);
      setMenu3(menu.menu3);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetch() {
      let result = await footerSettingUtils();

      if (result === "TypeError: Failed to fetch") {
        return;
      }
      const object = getFooterSetting(result);
      setSetting(object);
    }

    fetch();
  }, []);

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (email && email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g)) {
      try {
        setLoading(true);
        const result = await footerServ.newsLetterAPI({ email_address: email });
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
      } catch (e) {
        notification("error", e);
      } finally {
        setLoading(false);
      }
    } else {
      notification("error", "Invalid Email");
    }
  };
  return (
    <div>
      {htmlFooter ? (
        <div id="extra-footer-content" className="html-footer container">
          {ReactHtmlParser(htmlFooter)}
        </div>
      ) : (
        ""
      )}
      <div className="theme-footer" id="mainFooter">
        <div className="container _pb-30">
          {/*  _ph-mob-0 */}
          <div className="col-md-12">
            <div className="row">
              {/* {pathname !== "/flightDetails" &&
                pathname !== "/googleresults" &&
                pathname !== "/search" ? 
                  <>
                    <div className="col-sm-12 col-md-8 col-lg-8">
                      {Menu2.length > 0 ? (
                        <div className="theme-footer-sec mob_theme-footer-sec">
                          <h5 className="theme-footer-section-title footer-followus title-color">
                            {Menu2[0].menu.name}
                          </h5>
                        </div>
                      ) : null}
                      {[...Array(Math.ceil(Menu2.length / 6))].map((_, j) => (
                        <div
                          key={j}
                          className="col-sm-4 col-md-4 col-lg-4 destination-list"
                        >
                          <div className="theme-footer-sec destination-inner-section">
                            <ul className="footer-ul row" id="footer">
                              {Menu2.slice(j * 10, (j + 1) * 10).map(
                                (el, i) => {
                                  return (
                                    <li key={el.id} className="col-xs-12">
                                      <Link
                                        style={{
                                          color: "#068dc8",
                                          textDecoration: "none",
                                          fontWeight: "600",
                                        }}
                                        to={{
                                          pathname: el.link,
                                          state: el.link,
                                        }}
                                      >
                                        {el.name}{" "}
                                        {Menu2.length - 1 > i ? (
                                          <span className="bar-footer" />
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                : <></>} */}

              {/* newsletter */}
              {/* {pathname !== "/flightDetails" &&
                pathname !== "/googleresults" &&
                pathname !== "/search" && (
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="theme-footer-sec">
                      <div className="theme-footer-sec">
                        <h5 className="theme-footer-section-title footer-followus title-color">
                          Great deals are just an email away!
                        </h5>
                        <p className="contactus-color">
                          Enter your email to save £££s on great value flights
                        </p>
                      </div>
                      <div className="theme-footer-sec newsletter">
                        <div className="content">
                          <div className="input-group">
                            <input
                              type="email"
                              onChange={emailChange}
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <span className="input-group-btn">
                              <button
                                className="btn"
                                type="submit"
                                onClick={handleSubmit}
                                aria-label="send-button"
                              >
                                {loading ? (
                                  <div
                                    className="newsletter-loader"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      textAlign: "center",
                                    }}
                                  >
                                    <CircularProgress />
                                  </div>
                                ) : (
                                  <i className="fa fa-paper-plane" />
                                )}
                              </button>
                            </span>
                          </div>
                          {showSuccess ? (
                            <p className="newsletter-greeting-text">
                              Thank you for subscribing with us.
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <p style={{ "text-align": "left", "padding-top": "5px" }}>
                      <span className="theme-footer-section-title footer-followus title-color">
                        {Setting.footer_contactus_title}
                      </span>
                    </p>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          <a
                            href={`tel:${Setting.contact_phone}`}
                            className="contactus-color"
                            aria-label="contact phone"
                          >
                            {Setting.contact_phone}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                          <a
                            href={"mailto:" + Setting.contact_email}
                            target="_blank"
                            className="contactus-color"
                          >
                            {Setting.contact_email}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-whatsapp" aria-hidden="true" />{" "}
                          <a
                            href="https://api.whatsapp.com/send?phone=447557646504&text=&source=&data="
                            className="contactus-color"
                            target="_blank"
                          >
                            {Setting.footer_whatsapp_number}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                          <span
                          >
                            {Setting.contact_address}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="" style={{ paddingLeft: "0px" }}>
                      <div>
                        <a
                          href="/pay-online"
                          className="contactus-color"
                          target="_blank"
                        >
                          <span
                            className="contact_footer_info"
                            style={{
                              background: "#f95b17",
                              padding: "10px 15px",
                              justifyContent: "center",
                            }}
                          >
                            
                            <span
                              className="text-center"
                              style={{ color: "#fff" }}
                            >
                              {" "}
                              Pay Online
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
              )} */}
              
                {
                  <div className="col-sm-12 col-md-7 col-lg-7" style={{ "text-align": "left" }}>
                                        <p style={{ "text-align": "left", "padding-top": "15px" }}>
                      <span className="theme-footer-section-title footer-followus title-color">
                        {Setting.footer_contactus_title}
                      </span>
                    </p>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          <a
                            href={`tel:${Setting.contact_phone}`}
                            className="contactus-color"
                            aria-label="contact phone"
                          >
                            {Setting.contact_phone}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                          <a
                            href={"mailto:" + Setting.contact_email}
                            target="_blank"
                            className="contactus-color"
                          >
                            {Setting.contact_email}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-whatsapp" aria-hidden="true" />{" "}
                          <a
                            href="https://api.whatsapp.com/send?phone=447557646504&text=&source=&data="
                            className="contactus-color"
                            target="_blank"
                          >
                            {Setting.footer_whatsapp_number}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div
                      className=""
                      style={{ paddingLeft: "0px", paddingBottom: "5px" }}
                    >
                      <div>
                        <span className="contact_footer_info">
                          <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                          <span
                          >
                            {Setting.contact_address}
                          </span>
                        </span>
                      </div>
                    </div> 
                </div> 
              }
                     <div className="col-sm-12 col-md-4 col-lg-4" style={{ "text-align": "left" }}>
                    <div className="theme-footer-sec">
                      <div className="theme-footer-sec">
                        <h5 className="theme-footer-section-title footer-followus title-color">
                          Great deals are just an email away!
                        </h5>
                        <p className="contactus-color">
                          Enter your email to save {getCurrency() === 'USD' ? '$$$' : '£££'}s on great value flights
                        </p>
                      </div>
                      <div className="theme-footer-sec newsletter">
                        <div className="content">
                          <div className="input-group">
                            <input
                              type="email"
                              onChange={emailChange}
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <span className="input-group-btn">
                              <button
                                className="btn"
                                type="submit"
                                onClick={handleSubmit}
                                aria-label="send-button"
                              >
                                {loading ? (
                                  <div
                                    className="newsletter-loader"
                                    style={{
                                      width: 24,
                                      height: 24,
                                      textAlign: "center",
                                    }}
                                  >
                                    <CircularProgress />
                                  </div>
                                ) : (
                                  <i className="fa fa-paper-plane" />
                                )}
                              </button>
                            </span>
                          </div>
                          {showSuccess ? (
                            <p className="newsletter-greeting-text">
                              Thank you for subscribing with us.
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {(pathname !== "/flightDetails" && pathname !== "/googleresults") ? 
                      <div className="" style={{ paddingLeft: "0px" }}>
                        <div>
                          <a
                            href="/pay-online"
                            className="contactus-color"
                            target="_blank"
                          >
                            <span
                              className="contact_footer_info"
                              style={{
                                background: "#f95b17",
                                padding: "10px 15px",
                                justifyContent: "center",
                              }}
                            >
                              
                              <span
                                className="text-center"
                                style={{ color: "#fff" }}
                              >
                                {" "}
                                Pay Online
                              </span>
                            </span>
                          </a>
                        </div>
                      </div> : ""
                    }
                  </div>
                  
            
            </div>
            <div className="row middle-footer">
              {/* Payments */}
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className="payment-section">
                  <span className="footer-followus footer-payment">
                    Pay securely with:
                  </span>
                  <div className="footer-icons">
                    <img
                      src={`/assets/img/payment-icons/american-express-curved-32px.png`}
                      alt="no-fee"
                      className="footer-payment-img"
                    />
                    <img
                      src={`/assets/img/payment-icons/discover-curved-32px.png`}
                      alt="no-fee"
                      className="footer-payment-img"
                    />
                    <img
                      src={`/assets/img/payment-icons/mastercard-curved-32px.png`}
                      alt="no-fee"
                      className="footer-payment-img"
                    />
                    <img
                      src={`/assets/img/payment-icons/visa-electron-curved-32px.png`}
                      alt="no-fee"
                      className="footer-payment-img"
                    />
                    <img
                      src={`/assets/img/payment-icons/maestro-curved-32px.png`}
                      alt="no-fee"
                      className="footer-payment-img"
                    />
                  </div>
                </div>
              </div>

              {/* partners Logo links */}
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 footer-logo">
                <ul className="footer-logo-link-ul">
                  <li>
                    <img
                      src={"/assets/ficon/atol-protected.png"}
                      alt="no-fee"
                      className="atol"
                    />
                  </li>
                  <li>
                    <img
                      src={"/assets/ficon/financial_logo-01.png"}
                      alt="no-fee"
                      className="financial"
                    />
                  </li>
                  <li>
                    <img
                      src={"/assets/ficon/protected_payments.jpg"}
                      alt="no-fee"
                      className="secure"
                    />
                  </li>
                  <li>
                    <img
                      src={"/assets/ficon/BTA_n.jpg"}
                      alt="no-fee"
                      className="bta"
                    />
                  </li>
                  <li style={{ paddingTop: "10px" }}>
                    <a
                      href="https://www.flynowpaylater.com/en/travellers"
                      target="_blank"
                    >
                      <img
                        src={HomeContext.home.setting.values.fly_now_logo}
                        className="flynow"
                        alt="flynow-paylater"
                      />
                    </a>
                  </li>
                  <li style={{ paddingTop: "10px" }}>
                    <img
                      src={"/assets/ficon/sage-pay-01.png"}
                      alt="no-fee"
                      className="sagepay"
                    />
                  </li>
                  <li style={{ zIndex: 1 }}>
                    <a
                      href="https://travelaware.campaign.gov.uk/ "
                      target="_blank"
                    >
                      <img
                        src={"/assets/ficon/travelAware.png"}
                        alt="no-fee"
                        className="travelaware"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              {/* * Follow Us * */}
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className="followus-section">
                  <span className="footer-followus footer-follow_us">
                    Follow Us:
                  </span>
                  <div className="footer-icons">
                    <a
                      href="https://www.facebook.com/FlightsTravels"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <i
                        className="fa fa-facebook social_footer_links fb"
                        aria-hidden="true"
                      />
                    </a>
                    <i
                      className="fa fa-instagram social_footer_links  insta"
                      aria-hidden="true"
                      aria-label="instagram"
                    />
                    <a
                      href="https://twitter.com/FlightsTravel2"
                      target="_blank"
                      aria-label="twitter"
                    >
                      <i
                        className="fa fa-twitter social_footer_links twtr"
                        aria-hidden="true"
                      />
                    </a>
                    <i
                      className="fa fa-youtube-play social_footer_links ytube"
                      aria-hidden="true"
                    />
                    <a
                      href="https://api.whatsapp.com/send?phone=447557646504&text=&source=&data="
                      target="_blank"
                      aria-label="whatsapp"
                    >
                      <i
                        className="fa fa-whatsapp social_footer_links whatsapp"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-12 " + window?.location?.pathname.trim === "/" ? "show_dest" : "noshow_dest"}>
              <div className="col-sm-12 col-md-12 col-lg-12">
                      {Menu2.length > 0 ? (
                        <div className="theme-footer-sec mob_theme-footer-sec">
                          <h5 className="theme-footer-section-title footer-followus title-color">
                            {Menu2[0].menu.name}
                          </h5>
                        </div>
                      ) : null}
                      {[...Array(Math.ceil(Menu2.length / 6))].map((_, j) => (
                        <div
                          key={j}
                          className="col-sm-4 col-md-3 col-lg-3 destination-list"
                        >
                          <div className="theme-footer-sec destination-inner-section">
                            <ul className="footer-ul row" id="footer">
                              {Menu2.slice(j * 8, (j + 1) * 8).map(
                                (el, i) => {
                                  return (
                                    <li key={el.id} className="col-xs-12">
                                      <Link
                                        style={{
                                          color: "#068dc8",
                                          textDecoration: "none",
                                          fontWeight: "600",
                                        }}
                                        to={{
                                          pathname: el.link,
                                          state: el.link,
                                        }}
                                      >
                                        {el.name}{" "}
                                        {Menu2.length - 1 > i ? (
                                          <span className="bar-footer" />
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div> 
          </div>
        </div>
        <div className="container-fluid">
          <div className="row disclaimer">
            {/* Quick Links */}
            <div className="col-md-12">
              {/*                 <div className="theme-footer-sec">
                  <h5 className="theme-footer-section-title footer-followus">
                    {" "}
                    {Menu1.map((el, i) => {
                      return i == 0 ? el.menu.name : null;
                    })}
                  </h5>
                </div> */}
              <div className="theme-footer-sec">
                <div className="footer-quicklinks" id="footer">
                  {Menu1.map((el, i) => {
                    return (
                      <div key={el.id}>
                        {el.link !== "https://esta.cbp.dhs.gov/esta" ? (
                          <>
                            <Link
                              key={el.id}
                              style={{
                                color: "#2dace3",
                                textDecoration: "none",
                              }}
                              to={el.link}
                            >
                              {el.name}{" "}
                              {Menu1.length - 1 > i ? (
                                <span className="bar-footer"></span>
                              ) : (
                                ""
                              )}
                            </Link>
                            <span style={{ color: "#fff", marginRight: "5px" }}>
                              {" "}
                              |{" "}
                            </span>
                          </>
                        ) : (
                          <a
                            style={{ color: "#2dace3", textDecoration: "none" }}
                            rel="noopener noreferrer"
                            href="https://esta.cbp.dhs.gov/esta"
                            target="_blank"
                          >
                            {el.name}
                          </a>
                        )}
                      </div>
                    );
                  })}
                  {/* <span style={{ color: "#fff" }}> | </span>
                    <a
                      style={{ color: "#2dace3", textDecoration: "none" }}
                      rel="noopener noreferrer"
                      href="/pay-online"
                    >
                      Pay Online
                    </a> */}
                </div>

                {/* <ul className="footer-ul" id="footer">
                    <li>
                      <a
                        style={{ color: "#4974fd", textDecoration: "none", fontWeight: "600" }}
                        rel="noopener noreferrer"
                        href="https://esta.cbp.dhs.gov/esta"
                        target="_blank">
                        Apply for ESTA
                      </a>
                    </li>
                  </ul> */}
              </div>
            </div>
            {/* Disclaimer */}
            <div className="col-md-12">
              <div className="container theme-footer-sec">
                <h5
                  className="theme-footer-section-title footer-followus"
                  style={{ color: "#ffffffd9" }}
                >
                  {Setting.footer_useful_information_title}
                </h5>

                <p
                  className="disclaimer_body"
                  style={{ margin: "0 0 15px" }}
                  dangerouslySetInnerHTML={{
                    __html: Setting.footer_usefull_information,
                  }}
                />
              </div>
            </div>
            {/* Travel Advice */}
            <div className="col-md-12">
              <div className=" container theme-footer-sec">
                <p style={{ color: "#ffffffd9", fontSize: "13px" }}>
                  Travel ADVICE: the Foreign & Commonwealth Office has
                  up-to-date advice on health and safety across the globe, as
                  well as local laws, and passport and visa information. Please
                  check&nbsp;
                  <a
                    style={{ color: "#18a7ff", textDecoration: "underline" }}
                    rel="noopener noreferrer"
                    href="https://travelaware.campaign.gov.uk/"
                    target="_blank"
                  >
                    Travelaware.campaign.gov.uk
                  </a>
                  &nbsp;regularly for updates ahead of travel, as the advice can
                  change.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 theme-copyright">
              <p className="theme-copyright-text">
                {ReactHtmlParser(
                  HomeContext.home.setting.values.copyright_title
                )}
                <a href="#">
                  <img
                    src={HomeContext.home.setting.values.main_logo}
                    style={{ height: "34px", padding: "0 0 7px 1px" }}
                    alt="logo"
                  />
                </a>{" "}
                . All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
