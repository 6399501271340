import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import qs from "qs";

//export const apiUrl = "http://localhost:3004"; // Dev API Url
export const apiUrl = "https://api.flightstravels.com"; // Prod API Url
// export const apiUrl = "https://api-stage.flightstravel.co.uk"; // Test API Url

export const getAirlineName = (name) => {
  let airline = (name && name.split(",")[0]) || "";
  if (airline && airline.includes("-")) {
    airline = airline.split("-")[0];
  }
  return airline;
};
export const getAirlineCode = (name) => {
  let airline = name;
  if (airline && airline.includes(" - ")) {
    airline = airline.split(" - ")[1];
  }
  return airline;
};
export const getAirportName = (name) => {
  let airline = name;
  if (airline && airline.includes(" - ")) {
    airline = airline.split(" - ")[0];
  }
  return airline;
};

export const getPricePerAdult = (el = {}) => {
  let { AdultFares, ChildFares, InfantFares, totalFare } = el;
  AdultFares = AdultFares || {};
  ChildFares = ChildFares || {};
  InfantFares = InfantFares || {};
  totalFare = totalFare || {};
  const totalPassenger =
    AdultFares.quantity + ChildFares.quantity + InfantFares.quantity;
  return parseFloat(
    AdultFares.amount +
      AdultFares.tax +
      parseFloat(totalFare.OtherAmount / totalPassenger)
  ).toFixed(2);
};

export const isSame = (a = {}, b = {}) =>
  JSON.stringify(a) === JSON.stringify(b);

// SSR API functions
export const getMenus = (result) => {
  const menu = {
    menu1: [],
    menu2: [],
    menu3: [],
  };
  for (let i = 0; i < result.rows.length; i++) {
    if (result.rows[i].menuId === 1) {
      menu.menu1.push(result.rows[i]);
    } else if (result.rows[i].menuId === 2) {
      menu.menu2.push(result.rows[i]);
    } else if (result.rows[i].menuId === 3) {
      menu.menu3.push(result.rows[i]);
    }
  }
  return menu;
};

export const getFooterSetting = (result) =>
  result.rows?.reduce(function (obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});

export const getListSetting = (result) =>
  result.rows &&
  result.rows.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

export const pageBanner = (result) => {
  let pagesData = {};
  if (result.data) {
    for (let pg of result.data) {
      pagesData[pg.name] = pg;
    }
  }
  return pagesData;
};

export const homeSettings = (response) => {
  const home = { setting: { count: 0, values: {} } };
  home.setting.count = response.setting.count || 0;
  for (let i in response.setting.rows) {
    if (response.setting.rows[i].is_file === true) {
      home.setting.values[response.setting.rows[i].name] =
        response.setting.rows[i].logoImagePath;
    } else {
      home.setting.values[response.setting.rows[i].name] =
        response.setting.rows[i].value;
    }
  }
  response.setting = home.setting;
  return response;
};

export const leftContentBar = (
  <div className="col-md-3 theme-custom-sidebar">
    <div className="sidebar-menu-item">
      <Link to="/pg/about_us">About Us</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/pg/privacy_policy">Privacy Policy</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/pg/cookies_policy">Cookies Policy</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/pg/terms_conditions">Terms And Conditions</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/faq">FAQs</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/pg/fly_now_pay_later">Fly now pay later</Link>
    </div>
    <div className="sidebar-menu-item">
      <Link to="/contact">Customer Support</Link>
    </div>
    <div className="sidebar-menu-item">
      <a href="https://esta.cbp.dhs.gov/esta" target="_blank">
        Apply for ESTA
      </a>
    </div>
  </div>
);

export const getQueryData = (string) => {
  const data = string.substring(1);
  return qs.parse(data);
};
